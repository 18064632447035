import React from 'react';
import newsImage1 from '../img/news/news-1.jpg';
import newsImage2 from '../img/news/news-2.jpg';
import newsImage3 from '../img/news/news-3.jpg';
import newsImage4 from '../img/news/news-4.jpg';
import newsImage5 from '../img/news/news-5.jpg';

const newsItems = [
  {
    image: newsImage1,
    title: 'Social Media and Lifelong Learning',
    date: '04.11.2023',
    description: 'In the first module, participants explored the dynamics of social media and lifelong learning.  Interactive sessions, case studies, and practical exercises offered valuable insights into using social media for personal and professional growth. Under the guidance of our experts, participants explored innovative strategies for continuous learning, the power of digital platforms to expand their knowledge horizons.'
  },
  {
    image: newsImage2,
    title: 'Digital Literacy and Healthcare',
    date: '07.10.2023',
    description: 'In the second module, participants engaged in discussions on topics such as media literacy, fact-checking, online privacy, and intellectual property, which also allowed participants to better understand their rights and responsibilities as digital citizens. During the discussion of the topic of healthcare, participants delved into the pressing issues confronting healthcare systems. They also had discussions about an inclusive society and the challenges faced by individuals with disabilities.'
  },
  {
    image: newsImage3,
    title: 'Design Thinking and Sustainable Energy Sources',
    date: '22.10.2023',
    description: 'In the third module, participants were immersed in a dynamic learning experience aimed at fostering innovative solutions to environmental challenges. Through interactive workshops, participants gained practical skills in ideation, prototyping, and iteration, equipping them to tackle complex issues with confidence and ingenuity. In the session on Sustainable Energy Sources, participants explored the critical role of renewable energy in mitigating climate change and promoting environmental sustainability.'
  },

  {
    image: newsImage4,
    title: 'Financial Literacy and Management - Industry, Innovation, and Infrastructure',
    date: '07.10.2023',
    description: 'In the fourth module through interactive workshops and expert-led sessions, participants explored the pivotal role of industry, innovation, and infrastructure in driving economic growth and sustainable development. They examined key industry trends, innovative practices, and infrastructure projects shaping the future landscape. Additionally, inspired by sessions on environmentalism and climate change, participants engaged in a "plogging" activity to the botanical garden, sparking discussions along the way.'
  },
  {
    image: newsImage5,
    title: 'International law and Equality',
    date: '22.10.2023',
    description: 'In the fifth module, participants embarked on a journey to Samarkand alongside their counterparts from the YouLP programs in Kazakhstan and Kyrgyzstan. This module offered valuable insights into the intricacies of international law and underscored the significance of advancing global equality and justice. Additionally, participants visited the women\'s shelter "Rahmdillik," gaining insights into its efforts to provide assistance and refuge to women who have experienced violence and abuse.'
  }
];

const News = () => {
  return (
    <div id="news" className="text-[#343a40]">
      <h1 className="text-center mt-16 font-bold ml-6">Modules covered during the Program </h1>
      <div className="flex flex-col gap-[5vw] w-full max-w-[1400px] py-[5vw] md:py-[8vw] px-[5vw] m-auto">
        {newsItems.map((item, index) => (
          <NewsItem key={index} {...item} />
        ))}
      </div>
    </div>
  );
};

const NewsItem = ({ image, title, date, description }) => {
  return (
    <div className="flex flex-col md:flex-row items-center gap-[5vw]">
      <div className="w-full md:w-[40%] h-[auto]">
        <img src={image} alt="News Image" className="w-full h-auto" />
      </div>

      <div className="w-full md:w-[60%]">
        <h2 className="text-[5vw] md:text-[2.5vw] font-medium text-[#5F73F1]">
          {title}
        </h2>
        <p className="text-[2vw] md:text-[1.2vw]">
          {description}
        </p>
      </div>
    </div>
  );
};

export default News;
