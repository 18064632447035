import React from "react";
import imgOne from "../img/yulp/yulp-1.jpg";
import imgSecond from "../img/yulp/yulp-2.jpg";
import imgThird from "../img/yulp/yulp-3.jpg";
import imgFourth from "../img/yulp/yulp-4.jpg";
import imgFifth from "../img/yulp/yulp-5.jpg";
import imgSixth from "../img/yulp/yulp-6.jpg";

export default function Youlp() {
  return (
    <div id="youlp" className="container text-[#343a40]">
      <h1 className="text-center mt-16 font-bold">Young Leadership Program</h1>
      <div className="flex flex-col gap-10 lg:gap-20 xl:gap-32 py-10 md:py-16 lg:py-20 px-4 md:px-8 lg:px-12 xl:px-16 2xl:px-20">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-10 lg:gap-20 xl:gap-32 max-w-screen-xl mx-auto">
          <div>
            <h2 className="mb-4 text-2xl md:text-3xl font-medium">
              What is YouLP?
            </h2>
            <p className="text-lg md:text-xl">
              The Young Leadership Program (YouLP) is a youth initiative
              organized by the  <a href="https://www.mahorat.org/">
          Mahorat&Management </a> organization and the  <a href="https://www.fes.de/">
          Friedrich Ebert Stiftung in Uzbekistan (FES) </a>, aimed at empowering the youth
              of Uzbekistan by enhancing leadership, interpersonal, and
              community engagement skills. The first cohort of the program was
              held in the Fall of 2022, second cohort in the Fall of 2023 and
              empowered 20 talented youngsters from Uzbekistan.
            </p>
          </div>
          <div>
            <img src={imgOne} alt="about Young Leadership Program" />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-10 lg:gap-20 xl:gap-32 max-w-screen-xl mx-auto mt-10 md:mt-20">
          {/* For desktop and above */}
          <div className="hidden md:block">
            <img src={imgSecond} alt="Program Overview:" />
          </div>

          <div>
            <h2 className="mb-4 text-2xl md:text-3xl font-medium">
              Goals and objectives of YouLP
            </h2>
            <p className="text-lg md:text-xl">
              The program aims to empower young people in Uzbekistan by
              cultivating their leadership skills and community involvement.
              Provide an opportunity for young leaders in Uzbekistan to network
              with like-minded peers. Foster a sense of community and build a
              sustainable future.
            </p>
          </div>
          <div className="block md:hidden">
            <img src={imgSecond} alt="Program Overview:" />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-10 lg:gap-20 xl:gap-32 max-w-screen-xl mx-auto mt-10 md:mt-20">
          <div>
            <h2 className="mb-4 text-2xl md:text-3xl font-medium">
              Program Overview
            </h2>
            <p className="text-lg md:text-xl">
              The program lasts for three months and consists of a five-module
              Continuing Professional Development (CPD) curriculum. The training
              includes offline guest lectures by local experts, online
              zoom/telegram chat meetups featuring experts from abroad, case
              study analyses, practical field trips, a variety of team-building
              activities, and informal meetups initiated by the team. Each
              module consists of 8 hours of training, including team building
              activities, guest speaker lectures including a Q&A session, soft
              skills, case studies, or module tasks.
            </p>
          </div>
          <div>
            <img
              src={imgThird}
              alt="youlp members teaching in a class masterclass"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-10 lg:gap-20 xl:gap-32 max-w-screen-xl mx-auto mt-10 md:mt-20">
          {/* For desktop and above */}
          <div className="hidden md:block">
            <img src={imgFourth} alt="Program Overview:" />
          </div>

          <div>
            <h2 className="mb-4 text-2xl md:text-3xl font-medium">
              Can I participate?
            </h2>
            <p className="text-lg md:text-xl">
              The program is open to young people aged 18-25 from Uzbekistan.
              Candidates will be evaluated based on their values and principles,
              which are expected to align with and enhance the program's
              objectives. The selection process will be based on a thorough
              assessment of online application forms and interviews.
            </p>
          </div>
          <div className="block md:hidden">
            <img src={imgFourth} alt="Program Overview:" />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-10 lg:gap-20 xl:gap-32 max-w-screen-xl mx-auto mt-10 md:mt-20">
          <div>
            <h2 className="mb-4 text-2xl md:text-3xl font-medium">
              How does the Young Leader look for us?
            </h2>
            <p className="text-lg md:text-xl">
              Imagine a mosaic of vibrant youth, each tile representing a Young
              Leader empowered by YouLP. They're dynamic, driven, and eager to
              shape Uzbekistan's future. With diverse backgrounds and shared
              aspirations, they embody the spirit of leadership and community
              engagement. Step into this mosaic, where every Young Leader shines
              brightly, illuminating the path towards a sustainable and
              prosperous tomorrow.
            </p>
          </div>
          <div>
            <img
              src={imgFifth}
              alt="youlp members teaching in a class masterclass"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
